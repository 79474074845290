import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { CContainer, CSpinner } from "@coreui/react";

// routes config
import routes from "../routes";

const loading = (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    }}>
    <CSpinner
      color='primary'
      size='sm'
      style={{ width: "3rem", height: "3rem" }}
    />
  </div>
);
const AppContent = () => {
  return (
    <Suspense fallback={loading}>
      <Routes>
        {routes.map((route, idx) => {
          return (
            route.element && (
              <Route
                key={idx}
                path={route.path}
                exact={route.exact}
                name={route.name}
                element={<route.element />}
              />
            )
          );
        })}
        <Route path='/' element={<Navigate to='login' replace />} />
      </Routes>
    </Suspense>
  );
};

export default React.memo(AppContent);
