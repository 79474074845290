import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import DefaultLayout from "./layout/DefaultLayout";
// import TokenSetter from "./TokenSetter";

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='*' element={<DefaultLayout />} />
        {/* <Route path='/set_token' element={<TokenSetter />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
