import React from "react";
import {
  AppContent,
  AppSidebar,
  AppFooter,
  AppHeader,
} from "../components/index";

const DefaultLayout = () => {
  return (
    <div id='wrapper'>
      {/* <AppHeader /> */}
      <AppContent />
      {/* <AppFooter /> */}
    </div>
  );
};

export default DefaultLayout;
